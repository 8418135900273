'use client' // Error components must be Client Components
 
import ErrorHandler from '@components/ErrorHandler'
import { ErrorPageStyles } from '@styles/ErrorPageStyles'
import * as Sentry from "@sentry/nextjs";
import { useEffect } from 'react'
import { useRouter } from 'next/navigation';
import useHandleSignout from '@utils/useHandleSignout';
 
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
  }) {
  
  const handleSignout = useHandleSignout()
  useEffect(() => {
    // Log the error to an error reporting service
    console.log(error.message, 'error');
    if (error.message === "Error fetching data") { 

      handleSignout()
    }
    Sentry.captureException(error);
  }, [error])
 
  return (
    <ErrorPageStyles>
      <h2>Something went wrong!</h2>
      <ErrorHandler message={error.message} />
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button>
    </ErrorPageStyles>
  )
}